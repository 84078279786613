import {
  ValidateFunction,
  InputValidator,
  OnValidateCallback,
  ValidateArgs,
} from './inputValidationTypes';
import {
  addCustomValidityToValidationData,
  checkCustomValidity,
} from './validityUtils';
import { getCustomValidator } from './validationPropsSdkState';

export const createInputValidator = <TProps, TCompRef>(
  componentValidator: ValidateFunction<TProps>,
): InputValidator<TProps, TCompRef> => {
  const callbacks: Array<OnValidateCallback<TProps, TCompRef>> = [];
  return {
    onValidate: (cb: OnValidateCallback<TProps, TCompRef>) =>
      callbacks.push(cb),
    validate: ({
      viewerSdkAPI,
      showValidityIndication,
    }: ValidateArgs<TProps, TCompRef>) => {
      const { props, compId } = viewerSdkAPI;
      const validationDataResult = componentValidator(props);
      let updatedValidationData = validationDataResult;

      const customValidityMessage = checkCustomValidity(
        getCustomValidator(compId),
        props,
      );

      if (customValidityMessage) {
        updatedValidationData = addCustomValidityToValidationData(
          validationDataResult,
          customValidityMessage,
        );
      }

      callbacks.forEach((cb: OnValidateCallback<TProps, TCompRef>) =>
        cb({
          viewerSdkAPI,
          showValidityIndication,
          validationDataResult: updatedValidationData,
        }),
      );
    },
  };
};

export const createEmptyInputValidator = <TProps, TCompRef>(): InputValidator<
  TProps,
  TCompRef
> => {
  return {
    onValidate: (_cb: OnValidateCallback<TProps, TCompRef>) => {},
    validate: (_args: ValidateArgs<TProps, TCompRef>) => {},
  };
};
