import { LoginSocialBarSdkData } from '@wix/thunderbolt-components';
import {
  withValidation,
  composeSDKFactories,
  messages,
  reportError,
  assert,
} from '@wix/editor-elements-corvid-utils';

import { resolveAndFetchSvg, isValidSvgValue } from '../../../core/corvid/svg';
import { hiddenPropsSDKFactory } from '../../../core/corvid/props-factories';
import {
  ILoginSocialBarProps,
  ILoginSocialBarSDKFactory,
  ILoginSocialBarSDK,
  ILoginSocialBarSDKMenuItems,
  ILoginSocialBarSDKMenuItem,
  ILoginSocialBarSDKNavBarItems,
  ILoginSocialBarSDKNavBarItem,
} from '../LoginSocialBar.types';

type ICommonItem = Partial<
  ILoginSocialBarSDKMenuItem | ILoginSocialBarSDKNavBarItem
>;

const getCommonOptionalItemProps = ({
  link,
  visibleOnDesktop,
  visibleOnMobile,
  displayCount,
}: ICommonItem) => ({
  ...(link !== undefined && { link }),
  visibleOnDesktop: visibleOnDesktop ?? true,
  visibleOnMobile: visibleOnMobile ?? true,
  ...(typeof displayCount === 'number' && { displayCount }),
});

const cloneMenuItems = (menuItems: ILoginSocialBarSDKMenuItems) =>
  menuItems.map(({ label, ...optionalProps }) => ({
    label,
    ...getCommonOptionalItemProps(optionalProps),
  }));

const cloneNavBarItems = (navBarItems: ILoginSocialBarSDKNavBarItems) =>
  navBarItems.map(({ icon, label, ...optionalProps }) => ({
    icon,
    ...(label !== undefined && { label }),
    ...getCommonOptionalItemProps(optionalProps),
  }));

const sdkFactory: ILoginSocialBarSDKFactory = ({ setProps, sdkData }) => {
  const { isMobileView, baseSvgMediaUrl } = sdkData;
  const isItemVisiblePredicate = ({
    visibleOnDesktop,
    visibleOnMobile,
  }: ICommonItem) => (isMobileView ? visibleOnMobile : visibleOnDesktop);

  let menuItems: ILoginSocialBarSDKMenuItems = sdkData.menuItems;
  let navBarItems: ILoginSocialBarSDKNavBarItems = sdkData.navBarItems;

  return {
    get menuItems() {
      return cloneMenuItems(menuItems);
    },

    set menuItems(value) {
      menuItems = value ? cloneMenuItems(value) : [];

      const menuItemsProp = menuItems
        .filter(isItemVisiblePredicate)
        .map(({ label, link, displayCount }) => ({
          label,
          link: link ? { href: link } : {},
          displayCount,
        }));

      setProps({ menuItems: menuItemsProp });
    },

    get navBarItems() {
      return cloneNavBarItems(navBarItems);
    },

    set navBarItems(value) {
      navBarItems = value ? cloneNavBarItems(value) : [];
      const visibleNavBarItems = navBarItems.filter(isItemVisiblePredicate);

      if (!visibleNavBarItems.length) {
        setProps({ iconItems: [] });
        return;
      }

      setProps(
        Promise.all(
          visibleNavBarItems.map(({ icon }) =>
            resolveAndFetchSvg(icon, baseSvgMediaUrl),
          ),
        ).then(icons => ({
          iconItems: visibleNavBarItems.map(
            ({ label, link, displayCount }, index) => ({
              iconSvgContent: icons[index],
              label: label ?? '',
              link: link ? { href: link } : {},
              displayCount,
            }),
          ),
        })),
      );
    },
  };
};

const sdkFactoryWithValidation = withValidation(
  sdkFactory,
  {
    type: ['object'],
    properties: {
      menuItems: {
        type: ['array', 'nil'],
        warnIfNil: true,
        items: {
          type: ['object'],
          properties: {
            label: { type: ['string'] },
            link: { type: ['string'] },
            visibleOnDesktop: { type: ['boolean'] },
            visibleOnMobile: { type: ['boolean'] },
            displayCount: { type: ['number', 'nil'] },
          },
          required: ['label'],
        },
      },
      navBarItems: {
        type: ['array', 'nil'],
        warnIfNil: true,
        items: {
          type: ['object'],
          properties: {
            link: { type: ['string'] },
            icon: { type: ['string'] },
            visibleOnDesktop: { type: ['boolean'] },
            visibleOnMobile: { type: ['boolean'] },
            displayCount: { type: ['number', 'nil'] },
          },
          required: ['icon'],
        },
      },
    },
  },
  {
    navBarItems: [
      (value: ILoginSocialBarSDK['navBarItems']) => {
        let isValid = true;
        if (assert.isArray(value)) {
          value.forEach(({ icon }, index) => {
            if (!isValidSvgValue(icon)) {
              isValid = false;
              reportError(
                messages.invalidMenuItemMessage({
                  propertyName: 'navBarItems',
                  value: icon,
                  index,
                }),
              );
            }
          });
        }
        return isValid;
      },
    ],
  },
);

export const sdk = composeSDKFactories<
  ILoginSocialBarProps,
  ILoginSocialBarSDK,
  LoginSocialBarSdkData
>(sdkFactoryWithValidation, hiddenPropsSDKFactory);
