import { IMenuContainerSDKFactory } from '../MenuContainer.types';

export const sdk: IMenuContainerSDKFactory = ({ handlers, compId }) => ({
  open() {
    handlers.openMenuContainer(compId);
  },
  close() {
    handlers.closeMenuContainer(compId);
  },
});
