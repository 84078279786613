import { CorvidSDKPropsFactory } from '@wix/editor-elements-types';

export interface IHiddenPropSDK {
  hide: () => Promise<void>;
  show: () => Promise<void>;
  hidden: boolean;
}

export const hiddenPropsSDKFactory: CorvidSDKPropsFactory<
  {},
  IHiddenPropSDK
> = ({ setStyles, onLoadProperties }) => {
  const _state = {
    hidden: onLoadProperties.hidden,
  };
  return {
    hide: () => {
      setStyles({
        visibility: 'hidden',
      });
      _state.hidden = true;
      return Promise.resolve();
    },
    show: () => {
      setStyles({
        visibility: 'visible',
      });
      _state.hidden = false;
      return Promise.resolve();
    },
    get hidden() {
      return Boolean(_state.hidden);
    },
  };
};
