import { withValidation, assert } from '@wix/editor-elements-corvid-utils';
import { IMusicPlayerSDKFactory } from '../MusicPlayer.types';
import { isValidMediaSrc } from '../../../core/corvid/media/mediaSrcHandler';

const MusicPlayerSDKFactory: IMusicPlayerSDKFactory = ({
  setProps,
  props,
  registerEvent,
  compRef,
}) => ({
  get artistName() {
    return props.playlist[0].artistName;
  },
  set artistName(value) {
    setProps({ playlist: [{ ...props.playlist[0], artistName: value }] });
  },
  get trackName() {
    return props.playlist[0].trackName;
  },
  set trackName(value) {
    setProps({ playlist: [{ ...props.playlist[0], trackName: value }] });
  },
  get coverImage() {
    return props.playlist[0].cover;
  },
  set coverImage(value) {
    const cover = assert.isNil(value) ? '' : value;
    if (isValidMediaSrc(cover, 'image')) {
      setProps({ playlist: [{ ...props.playlist[0], cover }] });
    }
  },
  get src() {
    return props.playlist[0].url;
  },
  set src(value) {
    const url = assert.isNil(value) ? '' : value;
    if (isValidMediaSrc(url, 'audio')) {
      setProps({ playlist: [{ ...props.playlist[0], url }] });
    }
  },

  get currentTime() {
    return props.currentTime;
  },

  get duration() {
    return props.duration;
  },

  get volume() {
    return props.volume;
  },

  set volume(value) {
    value && setProps({ volume: value / 100 });
  },

  get isMuted() {
    return props.muted;
  },

  get isPlaying() {
    return props.isPlaying;
  },
  mute() {
    setProps({ muted: true });
  },
  unmute() {
    setProps({ muted: false });
  },
  seek(time) {
    compRef.seek(time);
  },
  play() {
    compRef.play();
  },
  pause() {
    compRef.pause();
  },
  stop() {
    compRef.pause();
    compRef.seek(0);
  },
  togglePlay() {
    compRef.togglePlay();
  },
  onPlay(handler: () => void) {
    registerEvent('onPlay', handler);
  },
  onPause(handler: () => void) {
    registerEvent('onPause', handler);
  },
  onEnded(handler: () => void) {
    registerEvent('onEnded', handler);
  },
  onProgress(handler: () => void) {
    registerEvent('onTimeUpdated', handler);
  },
});

export const sdk: IMusicPlayerSDKFactory = withValidation(
  MusicPlayerSDKFactory,
  {
    type: ['object'],
    properties: {
      artistName: { type: ['string', 'nil'], warnIfNil: true },
      trackName: { type: ['string', 'nil'], warnIfNil: true },
      coverImage: { type: ['string', 'nil'], warnIfNil: true },
      src: { type: ['string', 'nil'], warnIfNil: true },
      currentTime: { type: ['number', 'nil'], warnIfNil: true },
      duration: { type: ['number', 'nil'], warnIfNil: true },
      volume: {
        type: ['number', 'nil'],
        minimum: 0,
        maximum: 100,
        warnIfNil: true,
      },
      seek: { type: ['function'], args: [{ type: ['number'] }] },
      onPlay: { type: ['function'], args: [{ type: ['function'] }] },
      onPause: { type: ['function'], args: [{ type: ['function'] }] },
      onEnded: { type: ['function'], args: [{ type: ['function'] }] },
      onProgress: { type: ['function'], args: [{ type: ['function'] }] },
    },
  },
);
