import {
  composeSDKFactories,
  reportError,
  withValidation,
} from '@wix/editor-elements-corvid-utils';
import {
  IWPhotoProps,
  IWPhotoSDK,
  IWPhotoSDKFactory,
  IWPhotoOwnSDKFactory,
} from '../WPhoto.types';
import {
  clickPropsSDKFactoryWithUpdatePlatformHandler,
  linkPropsSDKFactory,
  hiddenPropsSDKFactory,
} from '../../../core/corvid/props-factories';

import { parseMediaSrc } from '../../../core/corvid/media/mediaSrcHandler';
import { createMediaItemUri } from '../../../core/corvid/media/mediaItemUtils';

const externalUrl = /(^https?)|(^data)|(^blob)|(^\/\/)/;
const _wPhotoSDKFactory: IWPhotoOwnSDKFactory = ({ setProps, props }) => {
  return {
    get src() {
      const { uri, height, width, title } = props;

      if (externalUrl.test(uri)) {
        return uri;
      }

      const mediaItemUri = createMediaItemUri({
        mediaId: uri,
        width,
        height,
        title,
        type: 'image',
      });
      if (mediaItemUri.error) {
        return '';
      }
      return mediaItemUri.item || '';
    },
    set src(src: string) {
      if (!src) {
        setProps({ uri: '' });
      } else {
        const { height, width, title, mediaId, error } = parseMediaSrc(
          src,
          'image',
        );

        if (!error) {
          setProps({ height, width, title, uri: mediaId });
        } else {
          reportError(
            `The "src" property cannot be set to "src". It must be a valid URL starting with "http://", "https://", or "wix:image://".`,
          );
        }
      }
    },
    get alt() {
      return props.alt;
    },
    set alt(alt) {
      setProps({ alt });
    },
    get tooltip() {
      return props.title;
    },
    set tooltip(tooltip) {
      setProps({ title: tooltip });
    },
  };
};

export const WPhotoSDKFactory: IWPhotoOwnSDKFactory = withValidation(
  _wPhotoSDKFactory,
  {
    type: ['object'],
    properties: {
      src: { type: ['string', 'nil'], warnIfNil: true },
      alt: { type: ['string', 'nil'], warnIfNil: true },
    },
  },
);

export const sdk: IWPhotoSDKFactory = composeSDKFactories<
  IWPhotoProps,
  IWPhotoSDK
>(
  WPhotoSDKFactory,
  clickPropsSDKFactoryWithUpdatePlatformHandler,
  linkPropsSDKFactory,
  hiddenPropsSDKFactory,
);
