import { MouseEventHandler } from 'react';
import { withValidation } from '@wix/editor-elements-corvid-utils';
import { CorvidSDKPropsFactory } from '@wix/editor-elements-types';

export interface IElementPropsSDKProps {}

export interface IElementPropsSDK {
  onMouseIn: (cb: MouseEventHandler) => void;
  onMouseOut: (cb: MouseEventHandler) => void;
}
export interface IElementPropsSDKActions {
  onMouseEnter: MouseEventHandler;
  onMouseLeave: MouseEventHandler;
}

export const _elementPropsSDKFactory: CorvidSDKPropsFactory<
  IElementPropsSDKProps,
  IElementPropsSDK
> = ({ registerEvent }) => ({
  onMouseIn(cb: MouseEventHandler) {
    registerEvent('onMouseEnter', cb);
  },

  onMouseOut(cb: MouseEventHandler) {
    registerEvent('onMouseLeave', cb);
  },
});

export const elementPropsSDKFactory = withValidation(_elementPropsSDKFactory, {
  type: ['object'],
  properties: {
    onMouseIn: {
      type: ['function'],
      args: [
        {
          type: ['function'],
        },
      ],
    },
    onMouseOut: {
      type: ['function'],
      args: [
        {
          type: ['function'],
        },
      ],
    },
  },
});
