import { composeSDKFactories } from '@wix/editor-elements-corvid-utils';
import {
  ITinyMenuOwnSDKFactory,
  ITinyMenuProps,
  ITinyMenuSDK,
} from '../TinyMenu.types';
import { hiddenPropsSDKFactory } from '../../../core/corvid/props-factories';

// TODO - Own props
// eslint-disable-next-line no-empty-pattern
export const ownSDKFactory: ITinyMenuOwnSDKFactory = ({}) => ({});

export const sdk = composeSDKFactories<ITinyMenuProps, ITinyMenuSDK, any>(
  hiddenPropsSDKFactory,
  ownSDKFactory,
);
